<template>
  <div class="h-screen ">
    <div
      class="container mx-auto px-4 h-full"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
export default {

}
</script>
